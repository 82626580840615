/*
 * (c) Verra Technology Corporation
 */

/**
 * Provides utility functions for Elements
 */
class ElementUtil {
	
	/**
	 * @param element The element node to get the path for
	 * @return A query selector path exactly matching the element
	 */
	static getQuerySelectorPath( el ) {
		const stack = [];
		while( el != null && el.parentNode != null && el.nodeName !== 'HTML' && el.nodeName !== 'BODY' ) {
			let sibCount = 0;
			let sibIndex = 0;
			for ( let i = 0; i < el.parentNode.childNodes.length; i++ ) {
				let sib = el.parentNode.childNodes[i];
				 if ( sib.nodeName == el.nodeName ) {
					if ( sib === el ) sibIndex = sibCount;
					sibCount++;
				 }
			}
			if( el.hasAttribute != null && el.hasAttribute( 'id' ) && el.id != '' ) {
				stack.unshift( el.nodeName.toLowerCase() + '#' + el.getAttribute( 'id' ) );
			} else if( sibCount > 1 ) {
				stack.unshift( el.nodeName.toLowerCase() + ':nth-of-type(' + ( sibIndex + 1 ) + ')' );
			} else {
				stack.unshift( '> ' + el.nodeName.toLowerCase() );
			}
			el = el.parentNode;
		}
		return stack.join( ' ' );
	}

}

//

export default ElementUtil;