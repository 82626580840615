/*
 * (c) Verra Technology Corporation
 */

/**
 * Defines the types of available experience modifications
 */
const ExperienceModificationType = {
	
	/**
	 * A text change to an HTML text node. Modifies the Node.textContent property
	 */
	TEXT: 0,

	/**
	 * A markup (HTML) change to an HTML node. Replace the original HTML element
	 */
	MARKUP: 1,

	/**
	 * A markup (HTML) change to an HTML node. Replace the original HTML element
	 */
	STYLES: 2,

	/**
	 * Rearranges a specific element within the DOM
	 */
	REARRANGE: 3,

	/**
	 * Adds a new element at a specific position in the DOM
	 */
	ADD: 4,

	/**
	 * Removes a specific element from the DOM
	 */
	REMOVE: 5,

	/**
	 * Global CSS to be applied to the document
	 */
	CSS: 6,

	/** 
	 * Global JavaScript to be applied to the document
	 */
	JS: 7,

	/**
	 * Defines labels for the modification types
	 */
	MODIFICATION_TYPE_LABELS: {
		'0': 'Text',
		'1': 'Markup',
		'2': 'Style',
		'3': 'Rearrange',
		'4': 'Add Element',
		'5': 'Remove Element',
		'6': 'CSS',
		'7': 'JavaScript',
	}

};

export default ExperienceModificationType;