/*
 * (c) Verra Technology Corporation
 */

import ModifiableObject from "./ModifiableObject.mjs";

/**
 * Represents a modifications made as part of an experience
 */
class ExperienceModification {

	/**
	 * Defines the structure of the Channel class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: ExperienceModification
	}

	/**
	 * The type of change that was made
	 */
	type = null;

	/**
	 * The path of the modified, removed, or added or otherwise altered element, or where a new element or modfication 
	 * should exist on the page 
	 */
	path = null;

	/**
	 * The value representing the change. This could be text, markup, js, etc. depending on the type of change
	 */
	value = null;

	/**
	 * The original value before the change. This could be the original text or markup or it could be null depending on the type
	 */
	original = null;

	//

	/**
	 * Determines whether the modification is visible in the editor
	 */
	isVisible = true;

}

//

export default ExperienceModification;