/*
 * (c) Verra Technology Corporation
 */

import ExperienceModification from "./ExperienceModification";
import PublishableObject from "./PublishableObject.mjs";

/**
 * Represents an Experience
 */
class Experience extends PublishableObject {

	/**
	 * Defines the structure of the Channel class. Used with ObjectUtil.CreateFromDefinition
	 */
	static definition = {
		type: Experience,
		properties: {
			modifications: {
				type: ExperienceModification,
				collection: true
			}
		}
	}

	// Properties

	/**
	 * The ID of the account in which the Experience is associated
	 */
	accountId;

	/**
	 * The id of the Experience
	 */
	id = '';

	/**
	 * The site ID for which the Experience applies
	 */
	siteId;

	/**
	 * The name of the Experience
	 */
	name = '';

	/**
	 * The changes that have been made as part of the experience. Keyed by the type and path of the modification
	 */
	modifications = {};

}

//

export default Experience;