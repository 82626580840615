/*
 * (c) Verra Technology Corporation
 */

import Command from '../../commands/Command.mjs';
import UpSellModal from '../components/modals/UpSellModal';
import PermissionsManager from '../managers/PermissionsManager';
import Permissions from '../model/Permissions';
import SphereAdminSession from '../model/SphereAdminSession';
import OpenModalCommand from './OpenModalCommand';

/**
 * Adds a modification to an experience
 */
class AddModificationToExperienceCommand extends Command {
	
	#key;
	#modification;

	//

	/**
	 * Constructs the AddRuleToAudienceCommand
	 * @param modification The modification to add
	 */
	constructor( key, modification ) {
		super();
		this.#key = key;
		this.#modification = modification;
	}

	/**
	 * Handles execution of the command
	 */
	handleExecute() {
		const experience = SphereAdminSession.experience;
		const modifications = experience.modifications;
		const hasPermission = PermissionsManager.hasPermissionForMoreItems( Permissions.EXPERIENCES_MODIFICATIONS_MAX, modifications );

		if( hasPermission.allowed ) {
			modifications[ this.#key ] = this.#modification;
		} else {
			const msg = <p style={{ margin: 0 }}>You've reached your maximum number of allowed modifications per Experience. Your account only allows { hasPermission.permitted }.</p>;
			const upSell = <UpSellModal message={ msg } callout='experience_modifications'/>;
			const openModal = new OpenModalCommand(  'Limit Reached', upSell, '600px', true );
			openModal.execute();
		}

		return hasPermission.allowed;
	}
	
}

//

export default AddModificationToExperienceCommand;
