/*
 * (c) Verra Technology Corporation
 */

import ServiceRequestCommand from '../ServiceRequestCommand';
import SphereAdminSession from '../../model/SphereAdminSession';
import SphereAdminServices from '../../model/SphereAdminServices';
import ObjectUtil from '../../../util/ObjectUtil.mjs';
import Experience from '../../../model/Experience';

/**
 * Retrieves an Experience by ID
 */
class RetrieveExperienceRequest extends ServiceRequestCommand {

	/**
	 * Constructs the Request
	 * @param experienceId The ID of the Experience
	 */
	constructor( experienceId ) {
		super();
		this.experienceId = experienceId;
	}

	/**
	 * @return The service endpoint URL part. This is appended to SphereAdminServices.BASE_URL
	 */
   	getService() {
		return SphereAdminServices.RETRIEVE_EXPERIENCE + this.experienceId;
	}

	/**
	 * @return The parameters to send with the request
	 */
	getParams() {
		return { accountId: SphereAdminSession.selectedAccount.id };
	}

   /**
	* Handles completion of the command
	*/
	handleComplete() {
		SphereAdminSession.experience = null;
		const response = this.getResponse();
		// console.info( 'RetrieveContentRequest::handleComplete', response );
		if( response.success && response.result != null ) {
			SphereAdminSession.experience = ObjectUtil.createFromDefinition( response.result, Experience.definition );
			// console.info( 'SphereAdminSession.experience', SphereAdminSession.experience );
			if( SphereAdminSession.experiences == null ) SphereAdminSession.experiences = [];
			SphereAdminSession.experiences.push( SphereAdminSession.experience );
		}
	}
 
}

export default RetrieveExperienceRequest;
